.preceram-header {
  position: fixed;
  left: 0;
  right: 0;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #ffffff;
  z-index: 2;
}

.preceram-header-progress {
  position: fixed;
  top: 53px;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #f0f0f0;
  z-index: 2;
}

.preceram-home {
  position: fixed;
  width: 56px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  top: 0;
  left: 0;
  z-index: 2;

  &.promo {
    width: auto;
    height: 40px;
    margin-top: 24px;
    margin-left: 16px;

    img {
      height: 100%;
      width: auto;
    }
  }

  img {
    width: 24px;
    height: auto;
  }
}

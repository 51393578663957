@use "../../../variables";

.preceram-landing-page-desktop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 5;
  overflow: hidden;
  transition: all 0.3s;
  background: #000000;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  .background {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
  }

  .focus {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    pointer-events: none;
    background: rgba(38, 38, 38, 0.8);
    z-index: 1;
  }

  .logo {
    position: absolute;
    left: 120px;
    top: 56px;
    height: 48px;
    width: auto;
    z-index: 2;
  }

  .preceram-landing-page-language-selector {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;

    .selected {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: #ffffff;
      text-transform: uppercase;
    }

    .preceram-languages-container {
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      background: #3e3e3e;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      opacity: 0;
      transition: all 0.3s;
      pointer-events: none;

      &.open {
        opacity: 1;
        pointer-events: all;
      }

      .language {
        width: 200px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: #575757;
        }

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #f0f0f0;
        }

        .flag {
          height: 16px;
          width: auto;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }

  .content-container {
    position: absolute;
    left: 120px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    .title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 800;
      font-size: 60px;
      line-height: 70px;
      color: #ffffff;
      margin-bottom: 24px;
    }

    .description {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 32px;
      color: #ffffff;
      max-width: 650px;
      margin-bottom: 40px;
      white-space: pre-wrap;
    }

    .start-btn-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      column-gap: 16px;

      .start-btn {
        box-sizing: border-box;
        background: variables.$main-color;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 8px 16px 8px 24px;
        border-radius: 60px;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: variables.$light-color;
        }

        &:active {
          background: variables.$dark-color;
        }

        &.alt {
          background: #ffffff;

          &:hover {
            background: variables.$light-color;

            .start-label {
              color: #ffffff;
            }

            svg * {
              fill: #ffffff;
            }
          }

          &:active {
            background: variables.$dark-color;

            .start-label {
              color: #ffffff;
            }

            svg * {
              fill: #ffffff;
            }
          }

          .start-label {
            color: variables.$main-color;
          }

          svg * {
            fill: variables.$main-color;
          }
        }

        .start-label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.0125em;
          color: #ffffff;
          margin-right: 8px;
          transition: all 0.3s;
        }

        svg {
          width: auto;
          height: 24px;
          transition: all 0.3s;

          * {
            fill: #ffffff;
            transition: all 0.15s;
          }
        }
      }
    }

    .watch-video-here {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0125em;
      color: #f0f0f0;
      margin-top: 24px;

      u {
        cursor: pointer;
      }
    }
  }

  .partners {
    width: auto;
    height: 64px;
    position: absolute;
    bottom: 40px;
    left: 120px;
    z-index: 2;
  }

  .powered-by {
    height: 46px;
    width: auto;
    position: absolute;
    bottom: 32px;
    right: 32px;
    z-index: 2;
  }
}

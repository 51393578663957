.preceram-description {
  position: fixed;
  width: 245px;
  left: 16px;
  bottom: 88px;
  font-family: Roboto;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.005em;
  color: #f0f0f0;
  transition: all 0.3s;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  z-index: 2;
}

.text-template {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s;

  &.active {
    pointer-events: all;
    opacity: 1;

    .modal-container {
      top: 104px;
    }
  }

  .focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(#262626, 0.8);
  }

  .modal-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    height: calc(100% - 104px);
    border-radius: 16px 16px 0 0;
    overflow: hidden;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #fafafa;

    svg {
      z-index: 1;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: auto;
    }

    .image {
      position: relative;
      width: 100%;
      height: 240px;
      overflow: hidden;

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        pointer-events: none;
      }
    }

    .title {
      position: relative;
      width: 100%;
      box-sizing: border-box;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 700;
      font-size: 21px;
      line-height: 21px;
      color: #262626;
      padding: 32px 16px 16px 16px;
    }

    .text {
      box-sizing: border-box;
      position: relative;
      width: 100%;
      height: calc(100% - 310px);
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #575757;
      padding: 0 16px 16px 16px;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }
    }
  }
}

@use "../../../../variables";

.text-template-desktop {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(#262626, 0.8);
  }

  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-center;

    .head {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: variables.$main-color;

      .title {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 21px;
        line-height: 21px;
        color: #fafafa;
        box-sizing: border-box;
        padding: 0 16px;
      }

      .close {
        width: 48px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        background: variables.$dark-color;

        svg {
          width: 24px;
          height: 24px;

          * {
            fill: #fafafa;
          }
        }
      }
    }

    .body {
      box-sizing: border-box;
      padding: 16px;
      min-width: 75vw;
      background: #fafafa;
      display: flex;
      align-items: flex-start;
      justify-content: center;

      .image {
        position: relative;
        flex: 2;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          position: relative;
          height: auto;
          width: 100%;
        }
      }

      .text {
        flex: 1;
        overflow-y: auto;
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        color: #575757;
        padding-left: 16px;
        box-sizing: border-box;
      }
    }
  }
}

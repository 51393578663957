@use "../../../variables";

$device-width: 768px;
$device-height: 576px;

.preceram-promo-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: linear-gradient(180deg, #b01723 0%, #101010 100%);
  display: flex;
  align-items: center;
  justify-content: center;

  @media only screen and (max-width: $device-width),
    (orientation: landscape) and (max-height: $device-height) {
    box-sizing: border-box;
    padding-top: 120px;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
    overflow-x: hidden;
    row-gap: 16px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  .promo-info {
    position: relative;
    flex-shrink: 0;
    box-sizing: border-box;
    flex-shrink: 0;
    padding-top: 56px;
    padding-left: 120px;
    padding-right: 56px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    row-gap: 16px;
    height: 42vw;
    width: 60vw;

    @media only screen and (max-width: $device-width),
      (orientation: landscape) and (max-height: $device-height) {
      width: 100vw;
      box-sizing: border-box;
      padding: 16px;
      height: fit-content;
    }

    .title {
      width: 75%;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      color: #ffffff;

      @media only screen and (max-width: $device-width),
        (orientation: landscape) and (max-height: $device-height) {
        width: 100%;
        font-size: 34px;
        line-height: 40px;
      }
    }

    .description {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 18px;
      line-height: 28px;
      color: #f0f0f0;
    }

    .resource-button {
      background: variables.$main-color;
      border: 2px solid variables.$main-color;
      border-radius: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 8px;
      padding: 8px 16px 8px 24px;
      cursor: pointer;
      transition: all 0.3s;

      @media only screen and (max-width: $device-width),
        (orientation: landscape) and (max-height: $device-height) {
        width: 100%;
        max-width: 360px;
        box-sizing: border-box;
        align-self: center;
      }

      &:hover {
        background: variables.$light-color;
        border: 2px solid variables.$light-color;
      }

      .label {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #ffffff;
      }

      svg {
        height: 24px;
        width: auto;
        filter: brightness(0) saturate(100%) invert(100%) sepia(10%)
          saturate(7496%) hue-rotate(252deg) brightness(120%) contrast(115%); //#ffffff
      }
    }

    .features {
      position: absolute;
      top: calc(100% - 88px - 56px);
      left: 120px;
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 42px;

      @media only screen and (max-width: $device-width),
        (orientation: landscape) and (max-height: $device-height) {
        position: relative;
        top: auto;
        left: auto;
        width: 100%;
        flex-wrap: wrap;
        justify-content: center;
        row-gap: 24px;
        box-sizing: border-box;
        padding: 16px 0;
      }

      .feature {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 16px;

        svg {
          height: 40px;
          width: auto;
        }

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #f0f0f0;
        }
      }
    }
  }

  .promo-gallery {
    position: relative;
    flex-shrink: 0;
    width: 40vw;
    height: 42vw;
    overflow: hidden;

    @media only screen and (max-width: $device-width),
      (orientation: landscape) and (max-height: $device-height) {
      width: 100vw;
      height: fit-content;
      box-sizing: border-box;
      padding: 16px;
      margin-bottom: 120px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      row-gap: 24px;

      @media only screen and (orientation: landscape) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
      }
    }

    .gallery {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40vw;
      height: 42vw;
      overflow: hidden;

      @media only screen and (max-width: $device-width),
        (orientation: landscape) and (max-height: $device-height) {
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        height: 272px;
        transform: none;
      }

      .image-container {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        height: 100%;
        transition: all 0.3s;
        overflow: hidden;

        &.previous {
          left: -100%;
          opacity: 0;
        }

        &.next {
          left: 100%;
          opacity: 0;
        }

        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          height: 100%;
          width: auto;
        }
      }
    }

    .characteristics {
      position: absolute;
      top: calc(100% - 88px - 56px);
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 1;
      width: 40vw;

      @media only screen and (max-width: $device-width),
        (orientation: landscape) and (max-height: $device-height) {
        position: relative;
        width: 100%;
        top: auto;
        left: auto;
        transform: none;
        flex-wrap: wrap;
        column-gap: 64px;
        row-gap: 24px;
      }

      .characteristic {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        row-gap: 16px;

        svg {
          height: 40px;
          width: auto;
        }

        .label {
          max-width: 88px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          text-align: center;
          color: #f0f0f0;
        }
      }
    }
  }
}

.toolbar {
  position: fixed;
  bottom: 88px;
  right: 16px;
  width: 48px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: center;
  row-gap: 32px;
  z-index: 2;

  svg {
    height: 24px;
    width: auto;
    filter: brightness(0) saturate(100%) invert(100%) sepia(3%) saturate(124%)
      hue-rotate(260deg) brightness(113%) contrast(96%);

    &.background {
      padding: 4px;
      background: rgba(62, 62, 62, 0.2);
      border-radius: 50%;
    }
  }
}

@use "../../../variables";

.contact-page-desktop {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  overflow: hidden;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 96px;
  box-sizing: border-box;

  @media only screen and (max-width: 1200px) {
    flex-direction: column;
    row-gap: 40px;
  }

  &.closed {
    opacity: 0;
    pointer-events: none;
  }

  .background {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
  }

  .focus {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    min-width: 100%;
    min-height: 100%;
    pointer-events: none;
    background: rgba(38, 38, 38, 0.8);
    z-index: 1;
  }

  .preceram-contact-page-language-selector {
    position: absolute;
    top: 32px;
    right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 3;

    .selected {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: #ffffff;
      text-transform: uppercase;
    }

    .preceram-languages-container {
      position: absolute;
      top: calc(100% + 4px);
      right: 0;
      background: #3e3e3e;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      opacity: 0;
      transition: all 0.3s;
      pointer-events: none;

      &.open {
        opacity: 1;
        pointer-events: all;
      }

      .language {
        width: 200px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: #575757;
        }

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #f0f0f0;
        }
      }
    }
  }

  .content-container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    height: 438px;

    @media only screen and (max-width: 1200px) {
      height: fit-content;
      width: 590px;
    }

    .contact-logo {
      height: 92px;
      width: auto;
      z-index: 2;
      margin-bottom: 72px;
    }

    .title {
      width: 492px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 34px;
      line-height: 40px;
      color: #ffffff;
      margin-bottom: 24px;
    }

    .description {
      width: 492px;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 32px;
    }

    .btn-container {
      display: flex;
      align-items: center;
      justify-content: center;
      column-gap: 16px;
      flex-direction: row;

      .btn {
        border-radius: 60px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 16px 0 24px;
        transition: all 0.3s;
        cursor: pointer;

        &.restart {
          background: variables.$main-color;

          &:hover {
            background: variables.$light-color;
          }
        }

        &.visit {
          background: #ffffff;

          .label {
            color: variables.$main-color;
          }

          svg * {
            fill: variables.$main-color;
          }

          &:hover {
            background: variables.$light-color;

            .label {
              color: #ffffff;
            }

            svg * {
              fill: #ffffff;
            }
          }
        }

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.0125em;
          color: #ffffff;
          transition: all 0.3s;
        }

        svg {
          width: 24px;
          height: auto;
          margin-left: 8px;

          * {
            transition: all 0.1s;
            fill: #ffffff;
          }
        }
      }
    }
  }

  .form-container {
    position: relative;
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    row-gap: 32px;

    .thanks {
      z-index: 2;
      width: 590px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
    }

    .form {
      z-index: 2;
      width: 590px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 32px;

      * {
        flex-shrink: 0;
        box-sizing: border-box;
      }

      .input-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-direction: row;

        .input-field {
          position: relative;
          height: 64px;
          flex: 1;

          &:first-child {
            margin-right: 8px;
          }

          &:last-child {
            margin-left: 8px;
          }

          &:focus {
            background: pink;
          }

          input {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: 100%;
            background: rgba(152, 151, 151, 0.6);
            border-radius: 8px;
            border: none;
            padding: 36px 16px 14px 16px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 14px;
            letter-spacing: 0.004em;
            color: #ffffff;

            &:placeholder-shown {
              & ~ .placeholder {
                top: 25px;
                font-size: 16px;
              }
            }

            &:focus {
              outline: none;

              & ~ .placeholder {
                top: 14px;
                font-size: 14px;
              }
            }
          }

          .placeholder {
            position: absolute;
            top: 14px;
            left: 16px;
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 14px;
            letter-spacing: 0.004em;
            color: #cfcfcf;
            z-index: 1;
            pointer-events: none;
            transition: all 0.3s;
          }
        }
      }

      textarea {
        width: 100%;
        height: 206px;
        resize: none;
        background: rgba(152, 151, 151, 0.6);
        border-radius: 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.004em;
        color: #ffffff;
        padding: 24px 16px;
        transition: all 0.3s;

        &::placeholder {
          color: #cfcfcf;
        }

        &:focus {
          outline: none;
        }
      }

      input.submit-button {
        align-self: flex-end;
        height: 40px;
        padding: 0 24px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #989797;
        border: 2px solid #989797;
        border-radius: 60px;
        background: transparent;
        pointer-events: none;
        transition: all 0.3s;

        &.enabled {
          pointer-events: all;
          border: 2px solid #ffffff;
          color: #ffffff;
          cursor: pointer;

          &:hover {
            background: #ffffff;
            color: variables.$main-color;
          }
        }
      }
    }
  }

  .powered-by {
    height: 46px;
    width: auto;
    position: absolute;
    bottom: 32px;
    right: 32px;
    z-index: 2;
  }

  .no-internet {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    z-index: 2;
    padding: 16px 32px;
    background: variables.$main-color;
    color: #ffffff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.0125em;
    border-radius: 60px;
    opacity: 0;
    transition: all 0.3s;

    &.show {
      top: 32px;
      opacity: 1;
    }
  }
}

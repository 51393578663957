.preceram-arrows {
  position: fixed;
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  bottom: 16px;
  left: 0;
  right: 0;
  z-index: 2;

  .preceram-pano-progress {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #ffffff;
  }

  .preceram-arrow {
    background: rgba(62, 62, 62, 0.2);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      margin-right: 8px;
    }

    &:last-child {
      margin-left: 8px;
    }

    svg {
      width: 24px;
      height: 24px;
      flex-shrink: 0;

      &.inactive {
        pointer-events: none;
        opacity: 0.5;
      }

      * {
        fill: #f0f0f0;
      }
    }
  }
}

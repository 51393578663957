.preceram-arrows-desktop {
  height: 48px;
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translate(-50%);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    transition: all 0.3s;
  }

  .preceram-arrow {
    position: relative;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(62, 62, 62, 0.2);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s;

    &.inactive {
      pointer-events: none;
      opacity: 0.5;
    }

    &:hover {
      background: rgba(62, 62, 62, 0.4);

      &.forward > .next-scene {
        left: 40px;
        opacity: 1;
      }

      &.back > .next-scene {
        right: 40px;
        opacity: 1;
      }
    }

    &:active {
      background: #3e3e3e;
    }

    svg {
      width: 24px;
      height: auto;
      transition: all 0.3s;
      filter: brightness(0) saturate(100%) invert(77%) sepia(51%) saturate(17%)
        hue-rotate(48deg) brightness(98%) contrast(94%);
    }

    &.back > .next-scene {
      right: 0;
    }

    &.forward > .next-scene {
      left: 0;
    }

    .next-scene {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #f0f0f0;
      opacity: 0;
      transition: all 0.3s;
      white-space: nowrap;
      pointer-events: none;
    }
  }

  .autoplay {
    width: 40px;
    height: auto;
    cursor: pointer;
    margin: 0 8px;
    filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(0%)
      hue-rotate(104deg) brightness(108%) contrast(106%);

    &.disabled {
      pointer-events: none;
      width: 0;
    }
  }
}

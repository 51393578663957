.preceram-menu {
  position: fixed;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: rgba(62, 62, 62, 0.4);
  bottom: 16px;
  left: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;

  svg {
    width: 24px;
    height: auto;

    * {
      fill: #fafafa;
    }
  }
}

.preceram-menu-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #262626;
  z-index: 3;
  transition: all 0.3s;
  transform-origin: left;

  &.open {
    left: 0;
  }

  .logo {
    position: absolute;
    top: 16px;
    left: 16px;
    height: 40px;
    width: auto;
  }

  .close {
    position: absolute;
    top: 24px;
    right: 16px;
    height: 24px;
    width: auto;
    filter: brightness(0) saturate(100%) invert(100%) sepia(96%) saturate(15%)
      hue-rotate(208deg) brightness(104%) contrast(100%);
  }

  .submenu {
    position: absolute;
    top: 92px;
    left: 0;
    width: 100%;
    height: 72px;
    display: flex;
    align-items: center;
    justify-content: center;

    .submenu-item {
      height: 100%;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      &.selected {
        border-bottom: 2px solid #ffffff;

        svg {
          filter: brightness(0) saturate(100%) invert(100%) sepia(6%)
            saturate(0%) hue-rotate(104deg) brightness(108%) contrast(106%);
        }

        .label {
          color: #ffffff;
        }
      }

      svg {
        width: 24px;
        height: auto;
        margin-bottom: 6px;
        transition: all 0.3s;
        filter: brightness(0) saturate(100%) invert(77%) sepia(51%)
          saturate(17%) hue-rotate(48deg) brightness(98%) contrast(94%);
      }

      .label {
        font-family: "Nunito Sans";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #989797;
      }
    }
  }

  .panoramas {
    position: absolute;
    top: 180px;
    left: 0;
    width: 100%;
    height: calc(100vh - 180px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
    column-gap: 16px;
    overflow-x: auto;
    box-sizing: border-box;
    padding: 0 16px;
    padding-bottom: 140px;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    .panorama {
      position: relative;
      flex-shrink: 0;
      width: 242px;
      height: 100%;
      overflow: hidden;
      border-radius: 8px;
      background: #000000;
      box-sizing: border-box;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
        pointer-events: none;
      }

      .label-container {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        background: linear-gradient(
          0deg,
          rgba(38, 38, 38, 0.8) 0%,
          rgba(38, 38, 38, 0) 100%
        );
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        box-sizing: border-box;
        padding: 0 0 16px 16px;

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          color: #ffffff;
        }
      }
    }

    span {
      display: none;
    }

    .resource {
      position: relative;
      flex-shrink: 0;
      width: 336px;
      height: 100%;
      overflow: hidden;
      margin-right: 16px;

      &:first-child {
        margin-left: 16px;
      }

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 100%;
        width: auto;
        pointer-events: none;
      }
    }
  }
}

.preceram-toolbar-desktop {
  position: fixed;
  bottom: 24px;
  right: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 48px;

  svg {
    width: 24px;
    height: 24px;
    margin-left: 16px;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(77%) sepia(51%) saturate(17%)
      hue-rotate(48deg) brightness(98%) contrast(94%);
    transition: all 0.3s;

    &.active {
      filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(0%)
        hue-rotate(104deg) brightness(108%) contrast(106%);
    }
  }
}

.preceram-info-container {
  position: fixed;
  bottom: 96px;
  right: 24px;
  box-sizing: border-box;
  max-width: 485px;
  background: rgba(33, 33, 33, 0.9);
  backdrop-filter: blur(7.5px);
  border-radius: 8px;
  padding: 24px;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;

    svg {
      width: 20px;
      height: auto;

      * {
        filter: none;
      }

      &.white {
        filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(0%)
          hue-rotate(104deg) brightness(108%) contrast(106%);
      }

      &.grey {
        cursor: pointer;
        filter: brightness(0) saturate(100%) invert(77%) sepia(51%)
          saturate(17%) hue-rotate(48deg) brightness(98%) contrast(94%);
      }
    }

    .title {
      padding-left: 16px;
      flex: 2;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #ffffff;
    }
  }

  .info {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #f0f0f0;
  }
}

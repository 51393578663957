.panorama-name-container {
  position: fixed;
  bottom: 28px;
  left: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  height: 40px;

  .name {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: #ffffff;
  }

  .progress {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    display: flex;
    align-items: center;
    letter-spacing: 0.015em;
    color: #cfcfcf;
  }
}

.guide-bar {
  position: fixed;
  left: 50%;
  bottom: 28px;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;

  .autoplay {
    width: 40px;
    height: auto;
    cursor: pointer;
    filter: brightness(0) saturate(100%) invert(100%) sepia(6%) saturate(0%)
      hue-rotate(104deg) brightness(108%) contrast(106%);
  }

  .stop {
    position: absolute;
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(62, 62, 62, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;

    svg {
      width: 24px;
      height: auto;
    }
  }

  .guide-arrow {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba(62, 62, 62, 0.2);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: rgba(62, 62, 62, 0.4);
    }

    &.inactive {
      opacity: 0.3;
      pointer-events: none;
    }

    &.back {
      margin-right: 8px;
    }

    &.forward {
      margin-left: 8px;
    }

    &.back > .next-scene {
      right: 0;
    }

    &.forward > .next-scene {
      left: 0;
    }

    .next-scene {
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 14px;
      color: #f0f0f0;
      opacity: 0;
      transition: all 0.3s;
      white-space: nowrap;
      pointer-events: none;
    }
  }
}

.header.no-controls {
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translate(-50%);
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  .title {
    text-align: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    display: flex;
    align-items: center;
    letter-spacing: 0.005em;
    color: #ffffff;
  }
}

.info-container {
  position: fixed;
  bottom: 76px;
  right: 24px;
  box-sizing: border-box;
  width: 485px;
  background: rgba(33, 33, 33, 0.9);
  backdrop-filter: blur(7.5px);
  border-radius: 8px;
  pointer-events: none;
  padding: 24px;

  &.no-controls {
    bottom: 28px;
    right: auto;
    left: 50%;
    transform: translate(-50%);
    width: 60vw;

    .info {
      text-align: center;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 24px;

    svg {
      width: 20px;
      height: auto;
      margin-right: 16px;
    }

    .title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.005em;
      color: #ffffff;
    }
  }

  .info {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.005em;
    color: #f0f0f0;
  }
}

.volume {
  position: fixed;
  bottom: 36px;
  right: 24px;
  cursor: pointer;
}

@use "../../variables";

.indication-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: rgba(16, 16, 16, 0.95);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  z-index: 3;
  pointer-events: none;
  opacity: 1;
  transition: all 2.5s cubic-bezier(1, 0, 0.5, 0.75);

  @media (max-width: variables.$breakpoint-mobile) {
    flex-direction: column;
    row-gap: 48px;

    @media (orientation: landscape) {
      flex-direction: row;
    }
  }

  &.hide {
    opacity: 0;
  }

  .indication {
    width: 186px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 24px;

    &.alt {
      width: 228px;
    }

    svg {
      width: auto;
      height: 64px;
      filter: brightness(0) saturate(100%) invert(100%) sepia(10%)
        saturate(7496%) hue-rotate(252deg) brightness(120%) contrast(115%); //#ffffff
    }

    .indication-text {
      width: 100%;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #cfcfcf;
    }
  }
}

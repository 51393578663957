.top-mask {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 80px;
  background: linear-gradient(
    180deg,
    rgba(38, 38, 38, 0.6) 0%,
    rgba(38, 38, 38, 0) 100%
  );
  pointer-events: none;
}

.bottom-mask {
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 235px;
  background: linear-gradient(
    0deg,
    rgba(38, 38, 38, 0.6) 0%,
    rgba(38, 38, 38, 0) 100%
  );
  pointer-events: none;
}

@use "../../variables";

.contact-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  z-index: 5;
  opacity: 1;
  transition: all 0.3s;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  .background {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: auto;
    pointer-events: none;
  }

  .focus {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    background: rgba(38, 38, 38, 0.8);
    z-index: 1;
    pointer-events: none;
  }

  .scrollable-container {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    z-index: 2;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }

    * {
      flex-shrink: 0;
      box-sizing: border-box;
    }

    .contact-logo {
      margin: 144px 16px 24px 16px;
      height: 80px;
      width: auto;
      z-index: 2;
    }

    .language-selector {
      z-index: 3;
      position: absolute;
      top: 16px;
      right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      .lang {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 0.8;
      }

      > svg {
        width: 24px;
        height: auto;
        filter: brightness(0) saturate(100%) invert(100%) sepia(6%)
          saturate(7500%) hue-rotate(233deg) brightness(120%) contrast(101%);
        opacity: 0.5;
      }

      .menu {
        position: absolute;
        top: calc(100% + 16px);
        right: 0;
        background: #3e3e3e;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 200px;
        padding: 8px 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.3s;

        &.open {
          transform: scaleY(1);
        }

        .lang-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;

          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            color: #f0f0f0;
          }

          .flag {
            width: auto;
            height: 16px;
          }
        }
      }
    }

    .content-container {
      z-index: 2;
      padding: 0 16px;
      margin-bottom: 64px;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;

      .title {
        width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 34px;
        line-height: 40px;
        color: #ffffff;
        margin-bottom: 24px;
      }

      .description {
        width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
        margin-bottom: 32px;
      }

      .btn {
        height: 40px;
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border-radius: 60px;
        margin-bottom: 16px;

        &.restart {
          background: variables.$main-color;
        }

        &.visit {
          border: 2px solid #ffffff;
        }

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          letter-spacing: 0.0125em;
          color: #ffffff;
          margin-right: 8px;
        }

        svg {
          width: 24px;
          height: auto;

          * {
            fill: #ffffff;
          }
        }
      }
    }

    .thanks {
      width: 100%;
      padding: 0 16px;
      margin-bottom: 32px;
      font-family: "Nunito Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 24px;
      line-height: 32px;
      color: #ffffff;
      z-index: 2;
    }

    .form {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: column;
      width: 100%;
      padding: 0 16px;
      margin-bottom: 64px;
      z-index: 2;

      .input-field {
        position: relative;
        width: 100%;
        height: 64px;
        margin-bottom: 16px;

        &:focus {
          background: pink;
        }

        input {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          background: rgba(152, 151, 151, 0.6);
          border-radius: 8px;
          border: none;
          padding: 36px 16px 14px 16px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 14px;
          letter-spacing: 0.004em;
          color: #ffffff;

          &:placeholder-shown {
            & ~ .placeholder {
              top: 25px;
              font-size: 16px;
            }
          }

          &:focus {
            outline: none;

            & ~ .placeholder {
              top: 14px;
              font-size: 14px;
            }
          }
        }

        .placeholder {
          position: absolute;
          top: 14px;
          left: 16px;
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 14px;
          letter-spacing: 0.004em;
          color: #cfcfcf;
          z-index: 1;
          pointer-events: none;
          transition: all 0.3s;
        }
      }

      textarea {
        width: 100%;
        height: 160px;
        overflow-y: auto;
        overflow-x: hidden;
        margin-bottom: 16px;
        resize: none;
        background: rgba(152, 151, 151, 0.6);
        border-radius: 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0.004em;
        color: #ffffff;
        padding: 24px 16px;
        transition: all 0.3s;

        &::placeholder {
          color: #cfcfcf;
        }

        &:focus {
          outline: none;
        }
      }

      input.submit-button {
        width: 100%;
        height: 40px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        text-align: center;
        letter-spacing: 0.0125em;
        color: #989797;
        border: 2px solid #989797;
        border-radius: 60px;
        background: transparent;

        &.enabled {
          color: #ffffff;
          border: 2px solid #ffffff;
        }
      }
    }

    .partners {
      height: 46px;
      width: auto;
      margin-left: 16px;
      margin-bottom: 16px;
      z-index: 2;
    }

    .powered-by {
      height: 46px;
      width: auto;
      margin-left: 16px;
      margin-bottom: 16px;
      z-index: 2;
    }
  }

  .no-internet {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    padding: 16px 32px;
    box-sizing: border-box;
    background: variables.$main-color;
    color: #ffffff;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 16px;
    letter-spacing: 0.0125em;
    z-index: 5;
    border-radius: 60px;
    text-align: center;
    width: calc(100% - 32px);
    filter: drop-shadow(0px 4px 6px rgba(11, 11, 11, 0.7));
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;

    &.show {
      opacity: 1;
      top: 16px;
    }
  }
}

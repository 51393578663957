$menu_width: 307px;

.preceram-menu-container-desktop {
  position: fixed;
  bottom: 0;
  left: -$menu_width;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  flex-direction: row;
  z-index: 1;
  pointer-events: none;
  transition: all 0.3s;

  &.open {
    left: 0;

    .content-container {
      .close {
        opacity: 1;
      }
    }
  }

  .content-container {
    position: relative;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    width: $menu_width;
    background: #262626;
    transition: all 0.3s;
    padding-top: 96px;
    box-sizing: border-box;
    overflow: hidden;
    transform-origin: left;
    pointer-events: all;

    .close {
      opacity: 0;
      transition: all 0.3s;
      position: absolute;
      top: 37px;
      right: 21px;
      width: 24px;
      height: 24px;
      cursor: pointer;
      filter: brightness(0) saturate(100%) invert(77%) sepia(51%) saturate(17%)
        hue-rotate(48deg) brightness(98%) contrast(94%);
    }

    .menu-title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.005em;
      color: #ffffff;
      padding-top: 24px;
      margin-left: 24px;
      margin-bottom: 24px;
      border-top: 1px solid #3e3e3e;
    }

    .panoramas {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
      overflow-y: auto;
      height: calc(100vh - 167px);

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      .panorama {
        position: relative;
        flex-shrink: 0;
        width: $menu_width;
        height: 150px;
        overflow: hidden;
        margin-bottom: 8px;
        cursor: pointer;
        background: #000000;

        &:hover {
          img {
            opacity: 1;
          }
        }

        img {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: auto;
          opacity: 0.9;
          transition: all 0.3s;
        }

        .label-container {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 50%;
          background: linear-gradient(
            0deg,
            rgba(38, 38, 38, 0.8) 0%,
            rgba(38, 38, 38, 0) 100%
          );
          z-index: 1;
          display: flex;
          align-items: flex-end;
          justify-content: flex-start;
          box-sizing: border-box;
          padding: 0 0 12px 24px;

          .label {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 16px;
            color: #f0f0f0;
          }
        }
      }
    }

    .resources {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-direction: row;
      flex-wrap: wrap;
      row-gap: 24px;
      column-gap: 16px;
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(100vh - 167px);
      padding: 16px;
      box-sizing: border-box;

      &::-webkit-scrollbar {
        width: 0;
        height: 0;
      }

      span {
        display: none;
      }

      .resource {
        position: relative;
        flex: 0.5;
        min-width: 40%;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-direction: column;

        .img-container {
          position: relative;
          width: 100%;
          height: 173px;
          margin-bottom: 6px;
          overflow: hidden;

          img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 100%;
            height: auto;
          }
        }

        .resource-title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 16px;
          color: #f0f0f0;
          width: 100%;
          text-align: left;
          box-sizing: border-box;
        }
      }
    }
  }

  .buttons-container-desktop {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    transition: all 0.3s;
    margin: 24px;

    * {
      transition: all 0.3s;
    }

    .button {
      width: 48px;
      height: 48px;
      background: rgba(62, 62, 62, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      cursor: pointer;
      pointer-events: all;

      &.active {
        background: #3e3e3e;

        &:hover {
          background: #3e3e3e;
        }

        ~ .resources-label {
          opacity: 1;
          transform: translate(0);
        }
      }

      &:hover {
        background: rgba(62, 62, 62, 0.4);

        ~ .resources-label {
          opacity: 1;
          transform: translate(0);
        }
      }

      svg {
        width: 24px;
        height: auto;
        filter: brightness(0) saturate(100%) invert(77%) sepia(51%)
          saturate(17%) hue-rotate(48deg) brightness(98%) contrast(94%);
      }
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      margin-top: 8px;

      &.disabled {
        .button {
          pointer-events: none;
          width: 0;
        }
      }

      .resources-label {
        transform: translate(-40px);
        pointer-events: none;
        margin-left: 8px;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        display: flex;
        align-items: center;
        color: #ffffff;
        opacity: 0;
        transition: all 0.3s;
      }

      .scene-info {
        margin-left: 8px;
        height: 48px;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        flex-direction: column;

        .name {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #ffffff;
          margin-bottom: 6px;
        }

        .progress {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          display: flex;
          align-items: center;
          letter-spacing: 0.015em;
          color: #cfcfcf;
        }
      }
    }
  }
}

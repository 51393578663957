@use "../../../../variables";

.preceram-modal-template-desktop {
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s;

  &.active {
    pointer-events: all;
    opacity: 1;
  }

  .focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(#262626, 0.8);
  }

  .modal-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    overflow: hidden;
    transition: all 0.3s;
    width: 60vw;
    height: 45vw;

    .image {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 0;
      width: 100%;
      height: 100%;
      overflow: hidden;

      img {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        min-width: 100%;
        min-height: 100%;
        pointer-events: none;
      }
    }

    .close {
      position: absolute;
      right: 24px;
      top: 24px;
      z-index: 1;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background: rgba(62, 62, 62, 0.4);
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      svg {
        width: 24px;
        height: auto;
        filter: brightness(0) saturate(100%) invert(100%) sepia(91%)
          saturate(66%) hue-rotate(182deg) brightness(119%) contrast(96%); //#fafafa
      }
    }

    .description {
      position: absolute;
      bottom: 0;
      left: 0;
      transform: translateY(100%) translateY(-68px) translateY(3px);
      width: 100%;
      background: rgba(62, 62, 62, 0.4);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      transition: all 0.3s;
      box-sizing: border-box;
      padding: 24px;

      &.open {
        transform: translateY(0);
        background: rgba(33, 33, 33, 0.9);
      }

      .header {
        padding: 0 0 16px 0;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .title {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0.0015em;
          color: #ffffff;
        }

        svg {
          width: 20px;
          height: 20px;
          filter: brightness(0) saturate(100%) invert(100%) sepia(91%)
            saturate(66%) hue-rotate(182deg) brightness(119%) contrast(96%); //#fafafa
        }
      }

      .body {
        box-sizing: border-box;
        width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #f0f0f0;
        white-space: prewrap;
      }
    }
  }
}

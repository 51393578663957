.business-control-bar {
  position: fixed;
  bottom: 28px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 8px;
  background: rgba(#000004, 0.85);
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
  padding: 8px;
  box-sizing: border-box;

  .controller {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    cursor: pointer;

    svg {
      width: 24px;
      height: auto;

      * {
        fill: #ffffff;
      }
    }

    &.green {
      svg {
        * {
          fill: #92de47;
        }
      }
    }

    &.end {
      background: #de4747;
    }
  }
}

.preceram-template {
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  opacity: 0;
  transition: all 0.3s;
  z-index: 4;

  &.active {
    pointer-events: all;
    opacity: 1;

    .modal-container {
      top: calc(50% - 180px);

      @media only screen and (orientation: landscape) {
        top: 0;
        left: 50%;
      }
    }
  }

  .focus {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    background: rgba(#262626, 0.8);
  }

  .modal-container {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    height: calc(50vh + 180px);
    border-radius: 8px 8px 0 0;
    overflow: hidden;
    transition: all 0.3s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background: #212121;

    @media only screen and (orientation: landscape) {
      width: 100vw;
      height: 100vh;
      height: calc(var(--vh, 1vh) * 100);
      top: 0;
      left: 150%;
      flex-direction: row;
      border-radius: 0;
      justify-content: center;
    }

    svg {
      z-index: 1;
      position: absolute;
      top: 16px;
      right: 16px;
      width: 24px;
      height: auto;
      background: rgba(62, 62, 62, 0.2);
      padding: 4px;
      border-radius: 50%;

      &.more-info {
        top: auto;
        bottom: 16px;
        background: transparent;
        pointer-events: none;
        opacity: 0;
        transition: all 0.3s;

        @media only screen and (orientation: landscape) {
          opacity: 1;
          pointer-events: all;

          &.inactive {
            opacity: 0;
            pointer-events: none;
          }
        }

        * {
          fill: #fafafa;
        }
      }
    }

    .image {
      position: relative;
      width: 100%;
      height: 240px;
      overflow: hidden;
      transition: all 0.3s;

      @media only screen and (orientation: landscape) {
        height: 100%;
        width: 60%;
      }

      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: auto;
        height: 100%;
        pointer-events: none;

        @media only screen and (orientation: landscape) {
          width: auto;
          height: 100%;
        }
      }
    }

    .info {
      width: 100%;
      height: calc(100% - 240px);
      transition: all 0.3s;

      @media only screen and (orientation: landscape) {
        position: relative;
        right: 0;
        height: 100%;
        width: 40%;
        overflow: hidden;

        &.inactive {
          right: -100%;
          width: 0;
          transform-origin: right;
        }
      }

      .title {
        position: relative;
        width: 100%;
        box-sizing: border-box;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #ffffff;
        padding: 32px 16px 16px 16px;

        @media only screen and (orientation: landscape) {
          width: 40vw;
        }
      }

      .text {
        box-sizing: border-box;
        position: relative;
        width: 100%;
        height: calc(100% - 72px);
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #f0f0f0;
        padding: 0 16px 16px 16px;
        overflow-y: scroll;

        @media only screen and (orientation: landscape) {
          width: 40vw;
        }

        &::-webkit-scrollbar {
          width: 0;
          height: 0;
        }
      }
    }
  }
}

@use "../../variables";

.link-hotspot {
  &.hover {
    z-index: 1;
  }

  .outter {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    background: rgba(variables.$main-color, 1);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.3s;

    &.grey {
      background: #3e3e3e;
    }

    svg {
      width: 24px;
      height: auto;

      * {
        fill: #ffffff;
      }
    }
  }

  .background-hoverable {
    position: absolute;
    top: 0%;
    left: 0;
    transform: translate(-50%, -100%);
    width: 40px;
    height: 40px;
    pointer-events: none;

    &.hover {
      pointer-events: all;
    }
  }

  .preview-space {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(-50%, -100%);
    z-index: 1;
    width: 200px;
    height: 200px;
    border-radius: 16px;
    background: #000000;
    overflow: hidden;
    opacity: 0;
    transition: all 0.3s;
    pointer-events: none;
    cursor: pointer;
    filter: drop-shadow(0px 4px 32px rgba(11, 11, 11, 0.7));

    &.hover {
      pointer-events: all;
      opacity: 1;
      transform: translate(-50%, -100%) translateY(-28px);
    }

    img {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      height: 100%;
      width: auto;
    }

    .next-space-container {
      position: absolute;
      left: 0;
      bottom: 0;
      padding: 16px;
      box-sizing: border-box;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .go-to-label {
        width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-transform: uppercase;
        color: #f0f0f0;
        margin-bottom: 8px;
      }

      .next-space {
        width: 100%;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 16px;
        color: #ffffff;
      }
    }

    .mask {
      z-index: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
      width: 100%;
      height: 50%;
      background: linear-gradient(180deg, rgba(33, 33, 33, 0) 0%, #212121 100%);
    }
  }
}

.preceram-home-desktop {
  position: fixed;
  z-index: 3;
  top: 24px;
  left: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 24px;

  img {
    height: 40px;
    width: auto;
    cursor: pointer;
  }

  .preceram-language-selector {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.hidden {
      opacity: 0;
      pointer-events: none;
    }

    .selected {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.005em;
      color: #ffffff;
      text-transform: uppercase;
    }

    .preceram-languages-container {
      position: absolute;
      top: calc(100% + 4px);
      left: 0;
      background: #3e3e3e;
      box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
      border-radius: 8px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 6px 0;
      opacity: 0;
      transition: all 0.3s;
      pointer-events: none;

      &.open {
        opacity: 1;
        pointer-events: all;
      }

      .language {
        width: 200px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 16px;
        background: transparent;
        cursor: pointer;
        transition: all 0.3s;

        &:hover {
          background: #575757;
        }

        .label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          display: flex;
          align-items: center;
          color: #f0f0f0;
        }

        .flag {
          width: auto;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;

          svg {
            height: 100%;
            width: auto;
          }
        }
      }
    }
  }
}

.close-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  pointer-events: none;
  z-index: 2;

  &.open {
    pointer-events: all;
  }
}

@use "../../variables";

.preceram-landing-page {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 3;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  transition: all 0.3s;
  overflow-x: hidden;
  overflow-y: auto;
  box-sizing: border-box;

  &.closed {
    pointer-events: none;
    opacity: 0;
  }

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  * {
    position: relative;
    box-sizing: border-box;
  }

  .background {
    position: fixed;
    bottom: 0;
    left: 0;
    width: auto;
    height: 100vh;
    //height: calc(var(--vh, 1vh) * 100);
    z-index: 0;

    @media (orientation: landscape) {
      width: 100vw;
      height: auto;
    }
  }

  .focus {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    z-index: 1;
    background: rgba(38, 38, 38, 0.8);
  }

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 3;
    margin-bottom: 64px;
    padding: 16px;

    img {
      height: 40px;
      width: auto;
    }

    .language-selector {
      display: flex;
      align-items: center;
      justify-content: center;

      .lang {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.005em;
        color: #ffffff;
        text-transform: uppercase;
        opacity: 0.8;
      }

      > svg {
        width: 24px;
        height: auto;
        filter: brightness(0) saturate(100%) invert(100%) sepia(6%)
          saturate(7500%) hue-rotate(233deg) brightness(120%) contrast(101%);
        opacity: 0.5;
      }

      .menu {
        position: absolute;
        top: calc(100% + 16px);
        right: 0;
        background: #3e3e3e;
        box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        width: 200px;
        padding: 8px 0;
        transform: scaleY(0);
        transform-origin: top;
        transition: all 0.3s;

        &.open {
          transform: scaleY(1);
        }

        .lang-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 8px 16px;

          span {
            font-family: "Roboto";
            font-style: normal;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            display: flex;
            color: #f0f0f0;
          }

          .flag {
            width: auto;
            height: 16px;
          }
        }
      }
    }
  }

  .content-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    row-gap: 16px;
    padding: 16px;
    z-index: 2;

    .title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 700;
      font-size: 48px;
      line-height: 56px;
      color: #ffffff;
      margin-bottom: 8px;
      width: 100%;

      @media (orientation: landscape) {
        align-self: flex-start;
      }
    }

    .description {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
      margin-bottom: 16px;
    }

    .btn-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      row-gap: 16px;

      @media only screen and (orientation: landscape) {
        flex-direction: row;
        column-gap: 16px;
      }

      .start-btn {
        width: 100%;
        height: 40px;
        background: variables.$main-color;
        border: 2px solid variables.$main-color;
        border-radius: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;

        &.alt {
          background: #ffffff;
          border-color: #ffffff;

          .start-label {
            color: variables.$main-color;
          }

          svg {
            filter: none;
            * {
              fill: variables.$main-color;
            }
          }
        }

        .start-label {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          letter-spacing: 0.0125em;
          color: #ffffff;
        }

        svg {
          filter: brightness(0) saturate(100%) invert(100%) sepia(6%)
            saturate(7500%) hue-rotate(233deg) brightness(120%) contrast(101%);
          width: auto;
          height: 24px;
        }
      }
    }

    .watch-video-here {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0125em;
      color: #f0f0f0;
      margin-top: 8px;
    }
  }

  .footer {
    position: sticky;
    top: 100%;
    margin-top: 50px;
    width: 100%;
    padding: 16px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
    row-gap: 16px;
    z-index: 2;

    svg {
      height: 48px;
      width: auto;

      &.powered-by {
        align-self: flex-start;
      }
    }
  }
}

.promo-page {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  opacity: 0;
  transition: all 1s;
  pointer-events: none;

  &.enabled {
    opacity: 1;
    pointer-events: all;
    z-index: 1;
  }

  .promo-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background: #000000;
    opacity: 0;
    transition: all 1s;
    pointer-events: none;

    &.enabled {
      opacity: 1;
      pointer-events: all;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: auto;
      pointer-events: none;
    }
  }
}

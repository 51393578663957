.advanced-autoplay-block-focus {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

.subtitle {
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%);
  background: #000000;
  color: #f0f0f0;
  font-size: 40px;
  z-index: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  width: 60vw;
  text-align: center;
}

.video-media-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  background: #000000;
  overflow: hidden;
  pointer-events: none;
  opacity: 0;
  transition: opacity 1.5s;

  &.active {
    opacity: 1;
    pointer-events: all;
  }

  video {
    display: block;
    width: 100%;
    min-width: 100%;
    min-height: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

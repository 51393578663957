.preceram-autoplay-container {
  position: fixed;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 16px;
  right: 16px;
  z-index: 2;

  &.disabled {
    pointer-events: none;

    svg {
      &.play,
      &.pause,
      & {
        * {
          fill: #989897;
        }
      }
    }
  }

  svg {
    width: 100%;
    height: auto;

    &.play * {
      fill: #ffffff;
    }

    &.pause * {
      fill: #ffffff;
    }
  }
}

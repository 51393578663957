@use "../../variables";

.modal-hotspot {
  .hotspot {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(0%, -50%);
    transform-origin: left;
    height: 40px;
    width: 40px;
    max-width: fit-content;
    border-radius: 20px;
    background: rgba(variables.$main-color, 1);
    transition: all 0.3s;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0 16px 0 7.5px;
    transition: all 0.6s;
    overflow: hidden;
    box-sizing: border-box;

    img {
      width: 24px;
      height: auto;
      filter: brightness(0) invert(1);
      transform: scale(1);
    }

    .hover-label {
      pointer-events: none;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      letter-spacing: 0.0015em;
      color: #ffffff;
      white-space: nowrap;
      margin-left: 13.5px;
      transform: scale(1);
    }

    &.hover {
      width: 400px;
    }

    .inner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: #fafafa;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      transition: all 0.3s;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 20px;
      width: auto;
    }
  }
}
